import "./footer.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

function Footer() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [popup, setPopup] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("setting") !== null && localStorage.getItem("setting") !== "undefined") {
            setSetting(JSON.parse(localStorage.getItem("setting")));
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setSetting(res.data.data[0]);
            });
        }
    }, []);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audio, setAudio] = useState(null);
    useEffect(() => {
        if (localStorage.getItem("mp3")?.length > 5) {
            setAudio(new Audio(localStorage.getItem("mp3")));
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setAudio(new Audio(res.data.data[0]?.mp3));
                localStorage.setItem("mp3", res.data.data[0]?.mp3);
            });
        }
        return () => {
            if (audio) {
                audio.pause();
            }
        };
    }, []);
    const toggleAudio = () => {
        if (audio) {
            if (audio.paused) {
                audio.play();
            } else {
                audio.muted = !audio.muted;
            }
            setIsPlaying(!isPlaying);
        }
    };
    return (
        <>
            <footer>
                <div class="footer_top">
                    <ul class="container">
                        <li>
                            <a href="#" data-type="phone">
                                <img src={require("../../image/common/icon-phone.png")} alt="" />
                                <p>Hotline</p>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src={require("../../image/common/icon-email.png")} alt="" />
                                <p>
                                    <span class="cfgMainEmail">CLB88@gmail.com</span>
                                </p>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src={require("../../image/common/icon-footerWork.png")} alt="" />
                                <p>ĐẠI LÝ</p>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <img src={require("../../image/common/icon-service.png")} alt="" />
                                <p>Chăm Sóc Khách Hàng</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="footer">
                    <div className="container">
                        <div className="marquee game-logo">
                            <div className="marquee-content">
                                <div className="logo-game logo-game-1" />
                                <div className="logo-game logo-game-2" />
                                <div className="logo-game logo-game-3" />
                                <div className="logo-game logo-game-4" />
                                <div className="logo-game logo-game-5" />
                                <div className="logo-game logo-game-6" />
                                <div className="logo-game logo-game-7" />
                                <div className="logo-game logo-game-8" />
                                <div className="logo-game logo-game-9" />
                                <div className="logo-game logo-game-10" />
                                <div className="logo-game logo-game-11" />
                                <div className="logo-game logo-game-12" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">Copyright © 2024 All Rights Reserved</div>
            </footer>
            <div className="br_right_bar">
                <div className="br_bar_mnav active">
                    <div className="br_sbgimg noticeBoard" onClick={toggleAudio}>
                        <span className="br_rightbar_icon">
                            {isPlaying ? (
                                <VolumeUpIcon style={{ width: "28px", height: "28px", marginTop: "10px" }} />
                            ) : (
                                <VolumeOffIcon style={{ width: "28px", height: "28px", marginTop: "10px" }} />
                            )}
                        </span>
                        <p>{!isPlaying ? "Bật nhạc" : "Tắt nhạc"}</p>
                    </div>
                    <div className="br_sbgimg liveChat" onClick={() => window.open(`${setting?.cskh}`, "_blank")}>
                        <span className="br_rightbar_icon">
                            <SupportAgentIcon style={{ width: "28px", height: "28px", marginTop: "10px" }} />
                        </span>
                        <p>Hỗ trợ trực tuyến</p>
                    </div>
                    <a className="br_sbgimg appDownload" href={setting?.down_link} target="_blank">
                        <span className="br_rightbar_icon">
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-appDownload"></use>
                            </svg>
                        </span>
                        <p>Tải App</p>
                    </a>
                    <div className="br_sbgimg promotion" onClick={() => navigate("/promotion")}>
                        <span className="br_rightbar_icon">
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-promotion"></use>
                            </svg>
                        </span>
                        <p>Khuyến mãi</p>
                    </div>
                </div>
            </div>
            {/*<div className="btn-info-mini" onClick={() => setPopup(true)}>
                <InfoIcon />
            </div>
            {popup === true && (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-header">Chú ý</div>
                        <div className="popup-content">
                            <p>
                                Để đảm bảo web được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách rút điểm quy đổi
                                trên 600.000.000 VND. Khi rút điểm cần thực hiện đóng phí duy trì theo hạn mức rút điểm như sau:
                            </p>
                            <ul>
                                <li>Hạn mức rút tài khoản từ 600 triệu - 1.000.000.000 VND tương ứng với 20% phí.</li>
                                <li>Hạn mức rút tài khoản trên 1.000.000.000 VND tương ứng với 30% phí.</li>
                            </ul>
                        </div>
                        <button onClick={() => setPopup(false)} className="popup-close">
                            Đóng
                        </button>
                    </div>
                </div>
            )}*/}
        </>
    );
}
export default Footer;
